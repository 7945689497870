/*.email-message-page h1 {*/
/*    color: #006608;*/
/*    text-transform: uppercase;*/
/*    font-size: 2rem;*/
/*}*/

/*.email-message-page {*/
/*    padding: 5rem 2rem;*/
/*    width: 100%;*/
/*}*/


/* form */

.email-message-page .form-container {
    background: #fff;
    width: 100%;
    padding: 25px 40px 10px 40px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

.email-message-page  .form-container .text {
    text-align: center;
    font-size: 41px;
    font-weight: 600;
    font-family: 'Poppins', sans-serif;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}


/* */


.email-message-page  .form-container form {
    padding: 30px 0 0 0;
}

.email-message-page .container form .form-row {
    display: flex;
    margin: 32px 0;
}

.email-message-page form .form-row .input-data {
    width: 100%;
    height: 40px;
    /*position: relative;*/

}

/*.email-message-page form .form-row .textarea {*/
/*    height: 70px;*/
/*}*/

.email-message-page .input-data input,
.textarea textarea {
    display: block;
    width: 100%;
    height: 100%;
    border: none;
    font-size: 17px;
    border-bottom: 2px solid rgba(0, 0, 0, 0.12);
}

.email-message-page .textarea textarea {
    resize: none;
    padding-top: 10px;
}

.email-message-page .input-data label {
    position: absolute;
    pointer-events: none;
    bottom: 10px;
    font-size: 16px;
    transition: all 0.3s ease;
}

.email-message-page .textarea label {
    width: 100%;
    bottom: 40px;
    background: #fff;
}

/*.email-message-page .input-data .underline {*/
/*    position: absolute;*/
/*    bottom: 0;*/
/*    height: 2px;*/
/*    width: 100%;*/
/*}*/
.email-message-page .submit-btn input {
    background: green;
    color: white;
    padding: 0 10px;
    width: 200px;
    border-radius: 5px;
}
/*@media (max-width: 700px) {*/
/*    .email-message-page .form-container .text {*/
/*        font-size: 30px;*/
/*    }*/

/*    .email-message-page .form-container form {*/
/*        padding: 10px 0 0 0;*/
/*    }*/

/*    .email-message-page .form-container form .form-row {*/
/*        display: block;*/
/*    }*/

/*    .email-message-page form .form-row .input-data {*/
/*        margin: 35px 0 !important;*/
/*    }*/

/*    .email-message-page .submit-btn .input-data {*/
/*        width: 40% !important;*/
/*    }*/
/*}*/