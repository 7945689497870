/*.footer {*/
/*    background: black;*/
/*    height: 500px;*/
/*}*/

/*.footer .google-map-image {*/
/*    height:400px;*/
/*    width: 70%;*/
/*}*/

/* Footer */
@import url('https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css');
section {
    padding: 60px 0;
}

section .section-title {
    text-align: center;
    color: #007b5e;
    margin-bottom: 50px;
    text-transform: uppercase;
}
#footer {
    /*background: #007b5e !important;*/
    background-color: rgba(12, 54, 47, 1);
}
#footer h5{
    padding-left: 10px;
    border-left: 3px solid #eeeeee;
    padding-bottom: 6px;
    margin-bottom: 20px;
    color:#ffffff;
}
#footer a {
    color: #ffffff;
    text-decoration: none !important;
    background-color: transparent;
    -webkit-text-decoration-skip: objects;
}
#footer ul.social li{
    padding: 3px 0;
}
#footer ul.social li a i {
    margin-right: 5px;
    font-size:25px;
    -webkit-transition: .5s all ease;
    -moz-transition: .5s all ease;
    transition: .5s all ease;
}
#footer ul.social li:hover a i {
    font-size:30px;
    margin-top:-10px;
}
#footer ul.social li a,
#footer ul.quick-links li a{
    color:#ffffff;
}
#footer ul.social li a:hover{
    color:#eeeeee;
}
#footer ul.quick-links li{
    padding: 3px 0;
    -webkit-transition: .5s all ease;
    -moz-transition: .5s all ease;
    transition: .5s all ease;
}
#footer ul.quick-links li:hover{
    padding: 3px 0;
    margin-left:5px;
    font-weight:700;
}
#footer ul.quick-links li a i{
    margin-right: 5px;
}
#footer ul.quick-links li:hover a i {
    font-weight: 700;
}

@media (max-width:767px){
    #footer h5 {
        padding-left: 0;
        border-left: transparent;
        padding-bottom: 0px;
        margin-bottom: 10px;
    }
}