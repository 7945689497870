/* ===== Google Font Import - Poppins ===== */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap');

* {
    padding: 0;
    margin: 0;
}

:root {
    --mainColor: #0C362F;
}

header {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 90%;
    height: 80px;
    background-color: rgba(12, 54, 47, 0.8);
    margin-top:10px;
    position: fixed;
    border-radius:30px;
    left:50%;
    transform: translate(-50%, 0);
    z-index: 1000;
}
header nav {
    width: 100%;
    height: 100%;
}
header .my-navbar {
    display: flex;
    justify-content: space-evenly;
    width: 100%;
    height: 100%;
    align-items: center;
    color:white;
}
header .my-navbar .contact-info {
    display: flex;
}

nav a {
    margin: 0px 10px;
    padding: 10px 7px;
    text-decoration: none;
    color:white;
    font-size: 1.2rem;
    cursor: pointer;
}
header .nav-btn {
    padding: 5px;
    cursor: pointer;
    background-color: transparent;
    border:none;
    outline: none;
    visibility: hidden;
    opacity: 0;
    font-size: 30px;
    color:white;
}
.hidden{
    display: none;
}

.dot {
    position: absolute;
    width: 8px;
    height: 8px;
    background-color: white; /* Color of the dot */
    border-radius: 50%; /* Makes it a circle */
    bottom: -4px;
    left: 50%;
    transform: translateX(-50%);
    opacity: 0;
    transition: opacity 0.3s ease;
}

nav a:hover .dot {
    opacity: 1;
    /*width: 100px;*/
    /*transition: 0.5s ease;*/
}

nav a:hover {
    color:unset;
    text-decoration: none;
}

.links {
    display: flex;
}

@media only screen and (max-width: 1100px) {
    header .nav-btn {
        visibility: visible;
        opacity: 1;
        position: absolute;
        top: 0.5rem;
        right:2rem;
    }

    header nav {
        position: fixed;
        top:0;
        left: 0;
        opacity: 1;
        /*height: 100%;*/
        height: calc(100vh - 20px);
        border-radius: 30px;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap:1.5rem;
        background-color: black;
        transition: 0.8s ease;
        transform: translate(-200vh);
        font-size: 30px;
        text-align: center;
    }
    header .my-navbar {
        display: flex;
        flex-direction: column;
    }
    header .my-navbar .links {
        display: flex;
        flex-direction: column;
    }
    header .my-navbar .contact-info {
        display: flex;
        flex-direction: column;
    }
    header .responsive-nav {
        transform:none;
        z-index: 100;
    }
    nav .nav-close-btn {
        position: absolute;
        top:1.2rem;
        right: 2rem;
        color:white;
        opacity: 1;
    }
    nav .nav-close-btn :hover {
        color: white;
    }
    .email {
        width: 100%;
        text-align: center;
    }

    .navbar-admin {
        flex-direction: column;
    }
}