/* Import the font */
@import url("https://fonts.googleapis.com/css2?family=Fredoka:wght@400;600&display=swap");

.line::before {
    content:"\A";
    width:5px;
    height:5px;
    border-radius:50%;
    background: black;
    display:inline-block;
    margin-bottom:4px;
    margin-right: 5px;
}

.line-light::before {
    content:"\A";
    width:5px;
    height:5px;
    border-radius:50%;
    background: white;
    display:inline-block;
    margin-bottom:4px;
    margin-right: 5px;
}

/* Define variables */
:root {
    --primaryFont: "Fredoka", sans-serif;
    --backgroundColor: #eceff1;
    --primaryColor: #81c784;
    --deleteColor: #ce0931;
}

.error-text {
    color: red;
}

.items-box {
    width: 100%;
    flex-wrap: wrap;
    display: flex;
    justify-content: flex-start;
}
/* Apply styles to the card */
.card {
    background-color: var(--backgroundColor);
    border-radius: 30px;
    text-align: center;
    padding: 15px;
    max-width: 300px;
    margin: 10px;
}

/* Apply styles to the illustration */
.card .ilustration {
    margin: 30px;
}


/* Apply styles to the heading */
.card h3 {
    /*font-family: var(--primaryFont);*/
    /*font-size: 2rem;*/
    /*line-height: 2.2rem;*/
    /*color: rgba(236, 239, 241, 0.2);*/
    /*font-weight: bold;*/
    /*margin: 30px 0;*/
}

/* Apply styles to the button */
.card button {
    font-family: var(--primaryFont);
    font-size: 0.8rem;
    font-weight: bold;
    padding: 5px 80px;
    border-radius: 25px;
    color: white;
    border: 0;
    margin: 5px 0;
    outline: none;
    transition: ease all 0.3s;
    cursor: pointer;
}
.send {
    background-color: var(--primaryColor);
    box-shadow: 10px 10px 10px rgba(129, 199, 132, 0.2), -10px -10px 10px rgba(129, 199, 132, 0.2);
}
.remove {
    background-color: var(--deleteColor);
}

/* Hover effect for the button */
.card button:hover {
    box-shadow: 20px 20px 20px rgba(129, 199, 132, 0.1), -20px -20px 20px rgba(129, 199, 132, 0.1);
    transform: translateY(-3px);
}

/* Active effect for the button */
.card button:active {
    transform: scale(0.9);
    box-shadow: inset -1px -1px 3px rgba(129, 199, 132, 0.3), -20px -20px 20px rgba(129, 199, 132, 0);
}
