.calendar-container {
    padding-bottom: 20px;
    display: flex;
    justify-content: center;
}



body {
    padding-right: 0 !important;
}



.not-created-month {
    pointer-events: none;
    background-color: #eceaea;
}


/* calendar styling */
.unavailable-date {
    background-color: #f01e2c; /* Red background for unavailable dates */
    color: white;
    font-weight: bold;
    pointer-events: none;
}
.available-date {
    background-color: #06c285; /* Green background for available dates */
    color: white;
    font-weight: bold;
}
.purple-red-light {
    background: linear-gradient( -45deg, red, red 50%, white 50%, white 50%, mediumpurple 50% );
    color: white;
    font-weight: bold;
}
.purple-red-dark {
    background: linear-gradient( -45deg, red, red 50%, white 50%, white 50%, purple 50% );
    color: white;
    font-weight: bold;
}

.red-purple-light {
    background: linear-gradient( -45deg, mediumpurple, mediumpurple 50%, white 50%, white 50%, red 50% );
    color: white;
    font-weight: bold;
}
.red-purple-dark {
    background: linear-gradient( -45deg, purple, purple 50%, white 50%, white 50%, red 50% );
    color: white;
    font-weight: bold;
}

.purple-light {
    background: mediumpurple;
    color: white;
    font-weight: bold;
}
.purple-dark {
    background: purple;
    color: white;
    font-weight: bold;
}
.purple-dark-purple-light{
    background: linear-gradient( -45deg, mediumpurple, mediumpurple 50%, white 50%, white 50%, purple 50% );
    color: white;
    font-weight: bold;
}
.purple-light-purple-dark {
    background: linear-gradient( -45deg, purple, purple 50%, white 50%, white 50%, mediumpurple 50% );
    color: white;
    font-weight: bold;
}

.green-purple-light {
    background: linear-gradient( -45deg, mediumpurple, mediumpurple 50%, white 50%, white 50%, #06c285 50% );
    color: white;
    font-weight: bold;
}
.green-purple-dark {
    background: linear-gradient( -45deg, purple, purple 50%, white 50%, white 50%, #06c285 50% );
    color: white;
    font-weight: bold;
}
.purple-green-light {
    background: linear-gradient( -45deg, #06c285, #06c285 50%, white 50%, white 50%, mediumpurple 50% );
    color: white;
    font-weight: bold;
}
.purple-green-dark {
    background: linear-gradient( -45deg, #06c285, #06c285 50%, white 50%, white 50%, purple 50% );
    color: white;
    font-weight: bold;
}

.red-green {
    background: linear-gradient( -45deg, #06c285, #06c285 50%, white 50%, white 50%, red 50% );
    color: white;
    font-weight: bold;
}
.green-red {
    background: linear-gradient( -45deg, red, red 50%, white 50%, white 50%, #06c285 50% );
    color: white;
    font-weight: bold;
}





.react-calendar {
    width: 500px;
    max-width: 100%;
    background-color: #fff;
    color: #222;
    box-shadow: 0 12px 24px rgba(0, 0, 0, 0.2);
    font-family: Arial, Helvetica, sans-serif;
    line-height: 1.125em;
    border: none;
}
.react-calendar__navigation button {
    color: #006608;
    min-width: 44px;
    background: none;
    font-size: 16px;
    margin-top: 8px;
}
.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
    background-color: #f8f8fa;

}
.react-calendar__navigation button[disabled] {
    background-color: #f0f0f0;
}
abbr[title] {
    text-decoration: none;
}
.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
    background: #f8f8fa;
    color: #006608;
    border-radius: 6px;
}
.react-calendar__tile--now {
    background: #00660833;
    border-radius: 6px;
    font-weight: bold;
    color: #006608;
}
.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
    background: #00660833;
    border-radius: 6px;
    font-weight: bold;
    color: #006608;
}
.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
    background: #f8f8fa;
}
.react-calendar__tile--active {
    background: #006608;
    border-radius: 6px;
    font-weight: bold;
    color: white;
}
.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
    background: #006608;
    color: white;
}
.react-calendar--selectRange .react-calendar__tile--hover {
    background-color: #f8f8fa;
    color:green;
}
.react-calendar__tile--range {
    background: #f8f8fa;
    color: #006608;
    border-radius: 0;
}
.react-calendar__tile--rangeStart {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
    background: #006608;
    color: white;
}
.react-calendar__tile--rangeEnd {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
    background: #006608;
    color: white;
}

/*.react-calendar__viewContainer {*/
/*    pointer-events: none;*/
/*}*/
