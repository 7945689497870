
img{
    max-width: 100%;
    height: auto;
    vertical-align: middle;
    display: inline-block;
}
.grid-wrapper > div {
    display: flex;
    justify-content: center;
    align-items: center;
}
.grid-wrapper > div > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 7px;
}
.grid-wrapper{
    display: grid;
    grid-gap: 10px;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    grid-auto-rows: 200px;
    grid-auto-flow: dense;
}
.grid-wrapper .wide {
    grid-column: span 2;
}
.grid-wrapper .tall {
    grid-column: span 2;
}
.grid-wrapper .big {
     grid-column: span 2;
    grid-row: span 2;
 }

.category-button {
    /*background-color: #006608;*/
    /*color: #fff;*/
    color: #006608;
    background-color: #fff;
    border:1px solid black;
    border-radius: 20px;
    padding: 10px 10px;
    font-size: 1rem;
    cursor: pointer;
    margin: 0 4px;
    transition: all 0.3s ease-in-out;
    width: 100px;
}
.category-button:hover {
    background-color: #006608;
    color: #fff;
}
.category-button:active {
    transform: scale(0.95);
    border: unset;
}








