.yes-paid {
    background-color: forestgreen; /* Change background color to green */
    box-shadow: inset 1em 1em forestgreen;
}

.no-paid {
    background-color: red; /* Change background color to green */
    box-shadow: inset 1em 1em red;
}

input[type="checkbox"] {
    -webkit-appearance: none;
    appearance: none;
    margin: 0;
    font: inherit;
    color: currentColor;
    width: 2.15em;
    height: 2.15em;
    border: 0.15em solid currentColor;
    border-radius: 0.15em;
    transform: translateY(-0.075em);
    display: grid;
    place-content: center;
}

input[type="checkbox"]::before {
    content: "";
    width: 1.65em;
    height: 1.65em;
    transform: scale(0);
    transition: 120ms transform ease-in-out;
}

input[type="checkbox"]:checked::before {
    transform: scale(1);
    background: white;
    transform-origin: bottom left;
    clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
}

/* Adjusted styles for the checked state */
input[type="checkbox"]:checked {
    background-color: forestgreen; /* Change background color to green */
    box-shadow: inset 1em 1em forestgreen; /* Change box-shadow to green */
}

.item {
    box-shadow: rgba(0, 0, 0, 0.1) 0px 54px 55px, rgba(0, 0, 0, 0.01) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
    margin: 30px;
    border-radius: 10px;
}

.item h5 {
    text-align: center;
    padding: 1rem;
}
