
.swiper {
    width: 350px;
    height: 100%;
    border-radius: 10px;
}

.swiper img {
    height: 250px;
}
.swiper-slide {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 18px;
    font-size: 22px;
    font-weight: bold;
    color: #fff;
}









/*!*NEW *!*/

/*.swiper-slide {*/
/*    text-align: center;*/
/*    font-size: 18px;*/
/*    background: #fff;*/

/*    !* Center slide text vertically *!*/
/*    display: flex;*/
/*    justify-content: center;*/
/*    align-items: center;*/
/*}*/


/*.swiper {*/
/*    width: 100%;*/
/*    height: 300px;*/
/*    margin-left: auto;*/
/*    margin-right: auto;*/
/*}*/

/*.swiper-slide {*/
/*    background-size: cover;*/
/*    background-position: center;*/
/*}*/

/*.mySwiper2 {*/
/*    height: 80%;*/
/*    width: 100%;*/
/*}*/

/*.mySwiper {*/
/*    height: 20%;*/
/*    box-sizing: border-box;*/
/*    padding: 10px 0;*/
/*}*/

/*.mySwiper .swiper-slide {*/
/*    width: 25%;*/
/*    height: 100%;*/
/*    opacity: 0.4;*/
/*}*/

/*.mySwiper .swiper-slide-thumb-active {*/
/*    opacity: 1;*/
/*}*/

/*.swiper-slide img {*/
/*    display: block;*/
/*    width: 100%;*/
/*    height: 100%;*/
/*    object-fit: cover;*/
/*}*/








.cottage-info {
    display: flex;
    justify-content: space-between;
    width: 100%;
}
.cottage-info .row {
    border-top:1px solid #e0e0e0;
    border-bottom:1px solid #e0e0e0;
    padding: 30px 0px;
}
.text-information {
    width: 50%;
}
.img-container {
    position: relative;
}
.icon-detail {
    background:#006608;
    padding: 20px;
    font-size: 30px;
    margin: 10px;
    color:white;
    width: 70px;
    height: 70px;
    display: flex;
    border-radius:15px
}
.description p {
    padding: 0;
    margin: 0;
}

.img-settings {
    width: 240px;
    height: 200px;
    border-radius: 20px;
}
.img2 {
    position: absolute;
    left: -150px;
    top:40px;
}

@media only screen and (max-width: 1000px) {
    .cottage-info {
        flex-direction: column;
    }
    .text-information {
        width: 100%;
    }
    .img-container {
        /*display: flex;*/
        /*justify-content: center;*/
        /*flex-wrap: wrap;*/
        position: relative;
        margin: auto;
    }
    .img-container img {
        padding: 2px;
    }
    .img-container .img1 {
        position: absolute;
        top: 0px;
    }
    .img-container .img2 {
        position: relative;
        left: -150px;
        top:40px;
    }
}

@media only screen and (max-width: 750px) {
    .img-settings {
        width: 160px;
        height: 120px;
    }
    .img-container {
        /*display: flex;*/
        /*justify-content: center;*/
        /*flex-wrap: wrap;*/
        position: relative;
        margin: auto;
    }
    .img-container img {
        padding: 2px;
    }
    .img-container .img1 {
        position: absolute;
        top: 0px;
    }
    .img-container .img2 {
        position: relative;
        left: -90px;
        top:40px;
    }

    .swiper {
        width: 250px;
    }
    .swiper img {
        height: 150px;
    }
}
