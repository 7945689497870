.main-page {
    background: linear-gradient(
            to bottom,
            rgba(38, 38, 37, 0.5),
            rgba(39, 39, 40, 0.7)
    ), url("../../../public/cottageImages/into_image.jpeg") no-repeat center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -ms-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    height: 100vh;
    width: 100%;
}

.main-page .rezervation-part {
    height: 75vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 300px;
    text-align: left;
}

.rezervation-button {
    background-color: #006608;
    border-radius: 6px;
    border-width: 0;
    box-shadow: rgba(50, 50, 93, .1) 0 0 0 1px inset, rgba(50, 50, 93, .1) 0 2px 5px 0, rgba(0, 0, 0, .07) 0 1px 1px 0;
    box-sizing: border-box;
    color: #fff;
    cursor: pointer;
    font-family: -apple-system,system-ui,"Segoe UI",Roboto,"Helvetica Neue",Ubuntu,sans-serif;
    font-size: 100%;
    height: 44px;
    line-height: 1.15;
    margin: 12px 0 0;
    outline: none;
    padding: 0 25px;
    text-align: center;
    text-transform: none;
    transition: all .2s,box-shadow .08s ease-in;
    width: 200px;
}
.go-back-button {
    background-color: #2facf1;
    border-radius: 6px;
    border-width: 0;
    box-shadow: rgba(50, 50, 93, .1) 0 0 0 1px inset, rgba(50, 50, 93, .1) 0 2px 5px 0, rgba(0, 0, 0, .07) 0 1px 1px 0;
    box-sizing: border-box;
    color: #fff;
    cursor: pointer;
    font-family: -apple-system,system-ui,"Segoe UI",Roboto,"Helvetica Neue",Ubuntu,sans-serif;
    font-size: 100%;
    height: 44px;
    line-height: 1.15;
    margin: 12px 0 0;
    outline: none;
    padding: 0 25px;
    text-align: center;
    text-transform: none;
    transition: all .2s,box-shadow .08s ease-in;
    width: 100px;
}
.rezervation-button:hover {
    color:black;
    background: #00AD0E;
    cursor:pointer;
    transition: 1s ease;
}
.rezervation-button:focus {
    outline:none;
}
