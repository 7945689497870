.interesting-facts-page .actions {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}
.interesting-facts-page .actions .icon .icon-detail {
    background:#006608;
    padding: 20px;
    font-size: 30px;
    margin: 10px;
    color:white;
    width: 70px;
    height: 70px;
    display: flex;
    border-radius:15px
}
.interesting-facts-page .actions .icon {
    width: 200px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
}
.interesting-facts-page .actions .icon p {
    color: rgba(0,0,0,0.5);
    text-align: center;
}