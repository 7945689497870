.img-item-info {
    width: 50%;
    height: 280px;
}
@media only screen and (max-width: 992px) {
    .img-item-info{
        width: 100%;
        height: unset;
    }
}
